.flag {
  display: inline-block;
  background-repeat: no-repeat;
}

.flag.flag-24 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(/static/media/sprite-flags-24x24.7ed12ca4.png);
  background-repeat: no-repeat;
}

.flag.flag-24.flag-abkhazia {
  background-position: -0px -0px;
}

.flag.flag-24.flag-basque-country {
  background-position: -24px -0px;
}

.flag.flag-24.flag-british-antarctic-territory {
  background-position: -48px -0px;
}

.flag.flag-24.flag-commonwealth {
  background-position: -72px -0px;
}

.flag.flag-24.flag-england {
  background-position: -96px -0px;
}

.flag.flag-24.flag-gosquared {
  background-position: -120px -0px;
}

.flag.flag-24.flag-kosovo {
  background-position: -144px -0px;
}

.flag.flag-24.flag-mars {
  background-position: -168px -0px;
}

.flag.flag-24.flag-nagorno-karabakh {
  background-position: -192px -0px;
}

.flag.flag-24.flag-nato {
  background-position: -216px -0px;
}

.flag.flag-24.flag-northern-cyprus {
  background-position: -240px -0px;
}

.flag.flag-24.flag-olympics {
  background-position: -264px -0px;
}

.flag.flag-24.flag-red-cross {
  background-position: -288px -0px;
}

.flag.flag-24.flag-scotland {
  background-position: -312px -0px;
}

.flag.flag-24.flag-somaliland {
  background-position: -336px -0px;
}

.flag.flag-24.flag-south-ossetia {
  background-position: -360px -0px;
}

.flag.flag-24.flag-united-nations {
  background-position: -384px -0px;
}

.flag.flag-24.flag-unknown {
  background-position: -0px -24px;
}

.flag.flag-24.flag-wales {
  background-position: -24px -24px;
}

.flag.flag-24.flag-ad {
  background-position: -48px -24px;
}

.flag.flag-24.flag-ae {
  background-position: -72px -24px;
}

.flag.flag-24.flag-af {
  background-position: -96px -24px;
}

.flag.flag-24.flag-ag {
  background-position: -120px -24px;
}

.flag.flag-24.flag-ai {
  background-position: -144px -24px;
}

.flag.flag-24.flag-al {
  background-position: -168px -24px;
}

.flag.flag-24.flag-am {
  background-position: -192px -24px;
}

.flag.flag-24.flag-an {
  background-position: -216px -24px;
}

.flag.flag-24.flag-ao {
  background-position: -240px -24px;
}

.flag.flag-24.flag-aq {
  background-position: -264px -24px;
}

.flag.flag-24.flag-ar {
  background-position: -288px -24px;
}

.flag.flag-24.flag-as {
  background-position: -312px -24px;
}

.flag.flag-24.flag-at {
  background-position: -336px -24px;
}

.flag.flag-24.flag-au {
  background-position: -360px -24px;
}

.flag.flag-24.flag-aw {
  background-position: -384px -24px;
}

.flag.flag-24.flag-ax {
  background-position: -0px -48px;
}

.flag.flag-24.flag-az {
  background-position: -24px -48px;
}

.flag.flag-24.flag-ba {
  background-position: -48px -48px;
}

.flag.flag-24.flag-bb {
  background-position: -72px -48px;
}

.flag.flag-24.flag-bd {
  background-position: -96px -48px;
}

.flag.flag-24.flag-be {
  background-position: -120px -48px;
}

.flag.flag-24.flag-bf {
  background-position: -144px -48px;
}

.flag.flag-24.flag-bg {
  background-position: -168px -48px;
}

.flag.flag-24.flag-bh {
  background-position: -192px -48px;
}

.flag.flag-24.flag-bi {
  background-position: -216px -48px;
}

.flag.flag-24.flag-bj {
  background-position: -240px -48px;
}

.flag.flag-24.flag-bl {
  background-position: -264px -48px;
}

.flag.flag-24.flag-bm {
  background-position: -288px -48px;
}

.flag.flag-24.flag-bn {
  background-position: -312px -48px;
}

.flag.flag-24.flag-bo {
  background-position: -336px -48px;
}

.flag.flag-24.flag-br {
  background-position: -360px -48px;
}

.flag.flag-24.flag-bs {
  background-position: -384px -48px;
}

.flag.flag-24.flag-bt {
  background-position: -0px -72px;
}

.flag.flag-24.flag-bw {
  background-position: -24px -72px;
}

.flag.flag-24.flag-by {
  background-position: -48px -72px;
}

.flag.flag-24.flag-bz {
  background-position: -72px -72px;
}

.flag.flag-24.flag-ca {
  background-position: -96px -72px;
}

.flag.flag-24.flag-cc {
  background-position: -120px -72px;
}

.flag.flag-24.flag-cd {
  background-position: -144px -72px;
}

.flag.flag-24.flag-cf {
  background-position: -168px -72px;
}

.flag.flag-24.flag-cg {
  background-position: -192px -72px;
}

.flag.flag-24.flag-ch {
  background-position: -216px -72px;
}

.flag.flag-24.flag-ci {
  background-position: -240px -72px;
}

.flag.flag-24.flag-ck {
  background-position: -264px -72px;
}

.flag.flag-24.flag-cl {
  background-position: -288px -72px;
}

.flag.flag-24.flag-cm {
  background-position: -312px -72px;
}

.flag.flag-24.flag-cn {
  background-position: -336px -72px;
}

.flag.flag-24.flag-co {
  background-position: -360px -72px;
}

.flag.flag-24.flag-cr {
  background-position: -384px -72px;
}

.flag.flag-24.flag-cu {
  background-position: -0px -96px;
}

.flag.flag-24.flag-cv {
  background-position: -24px -96px;
}

.flag.flag-24.flag-cw {
  background-position: -48px -96px;
}

.flag.flag-24.flag-cx {
  background-position: -72px -96px;
}

.flag.flag-24.flag-cy {
  background-position: -96px -96px;
}

.flag.flag-24.flag-cz {
  background-position: -120px -96px;
}

.flag.flag-24.flag-de {
  background-position: -144px -96px;
}

.flag.flag-24.flag-dj {
  background-position: -168px -96px;
}

.flag.flag-24.flag-dk {
  background-position: -192px -96px;
}

.flag.flag-24.flag-dm {
  background-position: -216px -96px;
}

.flag.flag-24.flag-do {
  background-position: -240px -96px;
}

.flag.flag-24.flag-dz {
  background-position: -264px -96px;
}

.flag.flag-24.flag-ec {
  background-position: -288px -96px;
}

.flag.flag-24.flag-ee {
  background-position: -312px -96px;
}

.flag.flag-24.flag-eg {
  background-position: -336px -96px;
}

.flag.flag-24.flag-eh {
  background-position: -360px -96px;
}

.flag.flag-24.flag-er {
  background-position: -384px -96px;
}

.flag.flag-24.flag-es {
  background-position: -0px -120px;
}

.flag.flag-24.flag-et {
  background-position: -24px -120px;
}

.flag.flag-24.flag-eu {
  background-position: -48px -120px;
}

.flag.flag-24.flag-fi {
  background-position: -72px -120px;
}

.flag.flag-24.flag-fj {
  background-position: -96px -120px;
}

.flag.flag-24.flag-fk {
  background-position: -120px -120px;
}

.flag.flag-24.flag-fm {
  background-position: -144px -120px;
}

.flag.flag-24.flag-fo {
  background-position: -168px -120px;
}

.flag.flag-24.flag-fr {
  background-position: -192px -120px;
}

.flag.flag-24.flag-ga {
  background-position: -216px -120px;
}

.flag.flag-24.flag-gb {
  background-position: -240px -120px;
}

.flag.flag-24.flag-gd {
  background-position: -264px -120px;
}

.flag.flag-24.flag-ge {
  background-position: -288px -120px;
}

.flag.flag-24.flag-gg {
  background-position: -312px -120px;
}

.flag.flag-24.flag-gh {
  background-position: -336px -120px;
}

.flag.flag-24.flag-gi {
  background-position: -360px -120px;
}

.flag.flag-24.flag-gl {
  background-position: -384px -120px;
}

.flag.flag-24.flag-gm {
  background-position: -0px -144px;
}

.flag.flag-24.flag-gn {
  background-position: -24px -144px;
}

.flag.flag-24.flag-gq {
  background-position: -48px -144px;
}

.flag.flag-24.flag-gr {
  background-position: -72px -144px;
}

.flag.flag-24.flag-gs {
  background-position: -96px -144px;
}

.flag.flag-24.flag-gt {
  background-position: -120px -144px;
}

.flag.flag-24.flag-gu {
  background-position: -144px -144px;
}

.flag.flag-24.flag-gw {
  background-position: -168px -144px;
}

.flag.flag-24.flag-gy {
  background-position: -192px -144px;
}

.flag.flag-24.flag-hk {
  background-position: -216px -144px;
}

.flag.flag-24.flag-hn {
  background-position: -240px -144px;
}

.flag.flag-24.flag-hr {
  background-position: -264px -144px;
}

.flag.flag-24.flag-ht {
  background-position: -288px -144px;
}

.flag.flag-24.flag-hu {
  background-position: -312px -144px;
}

.flag.flag-24.flag-ic {
  background-position: -336px -144px;
}

.flag.flag-24.flag-id {
  background-position: -360px -144px;
}

.flag.flag-24.flag-ie {
  background-position: -384px -144px;
}

.flag.flag-24.flag-il {
  background-position: -0px -168px;
}

.flag.flag-24.flag-im {
  background-position: -24px -168px;
}

.flag.flag-24.flag-in {
  background-position: -48px -168px;
}

.flag.flag-24.flag-iq {
  background-position: -72px -168px;
}

.flag.flag-24.flag-ir {
  background-position: -96px -168px;
}

.flag.flag-24.flag-is {
  background-position: -120px -168px;
}

.flag.flag-24.flag-it {
  background-position: -144px -168px;
}

.flag.flag-24.flag-je {
  background-position: -168px -168px;
}

.flag.flag-24.flag-jm {
  background-position: -192px -168px;
}

.flag.flag-24.flag-jo {
  background-position: -216px -168px;
}

.flag.flag-24.flag-jp {
  background-position: -240px -168px;
}

.flag.flag-24.flag-ke {
  background-position: -264px -168px;
}

.flag.flag-24.flag-kg {
  background-position: -288px -168px;
}

.flag.flag-24.flag-kh {
  background-position: -312px -168px;
}

.flag.flag-24.flag-ki {
  background-position: -336px -168px;
}

.flag.flag-24.flag-km {
  background-position: -360px -168px;
}

.flag.flag-24.flag-kn {
  background-position: -384px -168px;
}

.flag.flag-24.flag-kp {
  background-position: -0px -192px;
}

.flag.flag-24.flag-kr {
  background-position: -24px -192px;
}

.flag.flag-24.flag-kw {
  background-position: -48px -192px;
}

.flag.flag-24.flag-ky {
  background-position: -72px -192px;
}

.flag.flag-24.flag-kz {
  background-position: -96px -192px;
}

.flag.flag-24.flag-la {
  background-position: -120px -192px;
}

.flag.flag-24.flag-lb {
  background-position: -144px -192px;
}

.flag.flag-24.flag-lc {
  background-position: -168px -192px;
}

.flag.flag-24.flag-li {
  background-position: -192px -192px;
}

.flag.flag-24.flag-lk {
  background-position: -216px -192px;
}

.flag.flag-24.flag-lr {
  background-position: -240px -192px;
}

.flag.flag-24.flag-ls {
  background-position: -264px -192px;
}

.flag.flag-24.flag-lt {
  background-position: -288px -192px;
}

.flag.flag-24.flag-lu {
  background-position: -312px -192px;
}

.flag.flag-24.flag-lv {
  background-position: -336px -192px;
}

.flag.flag-24.flag-ly {
  background-position: -360px -192px;
}

.flag.flag-24.flag-ma {
  background-position: -384px -192px;
}

.flag.flag-24.flag-mc {
  background-position: -0px -216px;
}

.flag.flag-24.flag-md {
  background-position: -24px -216px;
}

.flag.flag-24.flag-me {
  background-position: -48px -216px;
}

.flag.flag-24.flag-mf {
  background-position: -72px -216px;
}

.flag.flag-24.flag-mg {
  background-position: -96px -216px;
}

.flag.flag-24.flag-mh {
  background-position: -120px -216px;
}

.flag.flag-24.flag-mk {
  background-position: -144px -216px;
}

.flag.flag-24.flag-ml {
  background-position: -168px -216px;
}

.flag.flag-24.flag-mm {
  background-position: -192px -216px;
}

.flag.flag-24.flag-mn {
  background-position: -216px -216px;
}

.flag.flag-24.flag-mo {
  background-position: -240px -216px;
}

.flag.flag-24.flag-mp {
  background-position: -264px -216px;
}

.flag.flag-24.flag-mq {
  background-position: -288px -216px;
}

.flag.flag-24.flag-mr {
  background-position: -312px -216px;
}

.flag.flag-24.flag-ms {
  background-position: -336px -216px;
}

.flag.flag-24.flag-mt {
  background-position: -360px -216px;
}

.flag.flag-24.flag-mu {
  background-position: -384px -216px;
}

.flag.flag-24.flag-mv {
  background-position: -0px -240px;
}

.flag.flag-24.flag-mw {
  background-position: -24px -240px;
}

.flag.flag-24.flag-mx {
  background-position: -48px -240px;
}

.flag.flag-24.flag-my {
  background-position: -72px -240px;
}

.flag.flag-24.flag-mz {
  background-position: -96px -240px;
}

.flag.flag-24.flag-na {
  background-position: -120px -240px;
}

.flag.flag-24.flag-nc {
  background-position: -144px -240px;
}

.flag.flag-24.flag-ne {
  background-position: -168px -240px;
}

.flag.flag-24.flag-nf {
  background-position: -192px -240px;
}

.flag.flag-24.flag-ng {
  background-position: -216px -240px;
}

.flag.flag-24.flag-ni {
  background-position: -240px -240px;
}

.flag.flag-24.flag-nl {
  background-position: -264px -240px;
}

.flag.flag-24.flag-no {
  background-position: -288px -240px;
}

.flag.flag-24.flag-np {
  background-position: -312px -240px;
}

.flag.flag-24.flag-nr {
  background-position: -336px -240px;
}

.flag.flag-24.flag-nu {
  background-position: -360px -240px;
}

.flag.flag-24.flag-nz {
  background-position: -384px -240px;
}

.flag.flag-24.flag-om {
  background-position: -0px -264px;
}

.flag.flag-24.flag-pa {
  background-position: -24px -264px;
}

.flag.flag-24.flag-pe {
  background-position: -48px -264px;
}

.flag.flag-24.flag-pf {
  background-position: -72px -264px;
}

.flag.flag-24.flag-pg {
  background-position: -96px -264px;
}

.flag.flag-24.flag-ph {
  background-position: -120px -264px;
}

.flag.flag-24.flag-pirate-black {
  background-position: -144px -264px;
}

.flag.flag-24.flag-pirate-white {
  background-position: -168px -264px;
}

.flag.flag-24.flag-pk {
  background-position: -192px -264px;
}

.flag.flag-24.flag-pl {
  background-position: -216px -264px;
}

.flag.flag-24.flag-pn {
  background-position: -240px -264px;
}

.flag.flag-24.flag-pr {
  background-position: -264px -264px;
}

.flag.flag-24.flag-ps {
  background-position: -288px -264px;
}

.flag.flag-24.flag-pt {
  background-position: -312px -264px;
}

.flag.flag-24.flag-pw {
  background-position: -336px -264px;
}

.flag.flag-24.flag-py {
  background-position: -360px -264px;
}

.flag.flag-24.flag-qa {
  background-position: -384px -264px;
}

.flag.flag-24.flag-ro {
  background-position: -0px -288px;
}

.flag.flag-24.flag-rs {
  background-position: -24px -288px;
}

.flag.flag-24.flag-ru {
  background-position: -48px -288px;
}

.flag.flag-24.flag-rw {
  background-position: -72px -288px;
}

.flag.flag-24.flag-sa {
  background-position: -96px -288px;
}

.flag.flag-24.flag-sb {
  background-position: -120px -288px;
}

.flag.flag-24.flag-sc {
  background-position: -144px -288px;
}

.flag.flag-24.flag-sd {
  background-position: -168px -288px;
}

.flag.flag-24.flag-se {
  background-position: -192px -288px;
}

.flag.flag-24.flag-sg {
  background-position: -216px -288px;
}

.flag.flag-24.flag-sh {
  background-position: -240px -288px;
}

.flag.flag-24.flag-si {
  background-position: -264px -288px;
}

.flag.flag-24.flag-sk {
  background-position: -288px -288px;
}

.flag.flag-24.flag-sl {
  background-position: -312px -288px;
}

.flag.flag-24.flag-sm {
  background-position: -336px -288px;
}

.flag.flag-24.flag-sn {
  background-position: -360px -288px;
}

.flag.flag-24.flag-so {
  background-position: -384px -288px;
}

.flag.flag-24.flag-sr {
  background-position: -0px -312px;
}

.flag.flag-24.flag-ss {
  background-position: -24px -312px;
}

.flag.flag-24.flag-st {
  background-position: -48px -312px;
}

.flag.flag-24.flag-sv {
  background-position: -72px -312px;
}

.flag.flag-24.flag-sy {
  background-position: -96px -312px;
}

.flag.flag-24.flag-sz {
  background-position: -120px -312px;
}

.flag.flag-24.flag-tc {
  background-position: -144px -312px;
}

.flag.flag-24.flag-td {
  background-position: -168px -312px;
}

.flag.flag-24.flag-tf {
  background-position: -192px -312px;
}

.flag.flag-24.flag-tg {
  background-position: -216px -312px;
}

.flag.flag-24.flag-th {
  background-position: -240px -312px;
}

.flag.flag-24.flag-tj {
  background-position: -264px -312px;
}

.flag.flag-24.flag-tk {
  background-position: -288px -312px;
}

.flag.flag-24.flag-tl {
  background-position: -312px -312px;
}

.flag.flag-24.flag-tm {
  background-position: -336px -312px;
}

.flag.flag-24.flag-tn {
  background-position: -360px -312px;
}

.flag.flag-24.flag-to {
  background-position: -384px -312px;
}

.flag.flag-24.flag-tr {
  background-position: -0px -336px;
}

.flag.flag-24.flag-tt {
  background-position: -24px -336px;
}

.flag.flag-24.flag-tv {
  background-position: -48px -336px;
}

.flag.flag-24.flag-tw {
  background-position: -72px -336px;
}

.flag.flag-24.flag-tz {
  background-position: -96px -336px;
}

.flag.flag-24.flag-ua {
  background-position: -120px -336px;
}

.flag.flag-24.flag-ug {
  background-position: -144px -336px;
}

.flag.flag-24.flag-us {
  background-position: -168px -336px;
}

.flag.flag-24.flag-uy {
  background-position: -192px -336px;
}

.flag.flag-24.flag-uz {
  background-position: -216px -336px;
}

.flag.flag-24.flag-va {
  background-position: -240px -336px;
}

.flag.flag-24.flag-vc {
  background-position: -264px -336px;
}

.flag.flag-24.flag-ve {
  background-position: -288px -336px;
}

.flag.flag-24.flag-vg {
  background-position: -312px -336px;
}

.flag.flag-24.flag-vi {
  background-position: -336px -336px;
}

.flag.flag-24.flag-vn {
  background-position: -360px -336px;
}

.flag.flag-24.flag-vu {
  background-position: -384px -336px;
}

@charset "UTF-8";

@font-face {
  font-family: "gaxon";
  src: url(/static/media/gaxon.32cebc42.eot);
  src: url(/static/media/gaxon.32cebc42.eot?#iefix) format("embedded-opentype"),
  url(/static/media/gaxon.36251c7b.woff) format("woff"),
  url(/static/media/gaxon.00fcca8e.ttf) format("truetype"),
  url(/static/media/gaxon.b1a63de8.svg#gaxon) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "gaxon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "gaxon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "a";
}

.icon-add-circle:before {
  content: "b";
}

.icon-affix:before {
  content: "c";
}

.icon-alert:before {
  content: "d";
}

.icon-alert-new:before {
  content: "e";
}

.icon-all-contacts:before {
  content: "f";
}

.icon-amchart:before {
  content: "g";
}

.icon-anchor:before {
  content: "h";
}

.icon-apps:before {
  content: "i";
}

.icon-apps-new:before {
  content: "j";
}

.icon-arrow-left:before {
  content: "k";
}

.icon-arrow-right:before {
  content: "l";
}

.icon-attachment:before {
  content: "m";
}

.icon-auth-screen:before {
  content: "n";
}

.icon-autocomplete:before {
  content: "o";
}

.icon-avatar:before {
  content: "p";
}

.icon-backtop:before {
  content: "q";
}

.icon-badge:before {
  content: "r";
}

.icon-basic-calendar:before {
  content: "s";
}

.icon-birthday:before {
  content: "t";
}

.icon-birthday-new:before {
  content: "u";
}

.icon-bitcoin:before {
  content: "v";
}

.icon-breadcrumb:before {
  content: "w";
}

.icon-burger:before {
  content: "x";
}

.icon-button:before {
  content: "y";
}

.icon-calendar:before {
  content: "z";
}

.icon-calendar-new:before {
  content: "A";
}

.icon-callout:before {
  content: "B";
}

.icon-camera:before {
  content: "C";
}

.icon-camera-2:before {
  content: "D";
}

.icon-card:before {
  content: "E";
}

.icon-cards-list-view:before {
  content: "F";
}

.icon-carousel:before {
  content: "G";
}

.icon-cascader:before {
  content: "H";
}

.icon-chart:before {
  content: "I";
}

.icon-chart-area:before {
  content: "J";
}

.icon-chart-area-new:before {
  content: "K";
}

.icon-chart-bar:before {
  content: "L";
}

.icon-chart-composed:before {
  content: "M";
}

.icon-chart-line:before {
  content: "N";
}

.icon-chart-pie:before {
  content: "O";
}

.icon-chart-radar:before {
  content: "P";
}

.icon-chart-radial:before {
  content: "Q";
}

.icon-chart-scatter:before {
  content: "R";
}

.icon-chart-tree:before {
  content: "S";
}

.icon-charvlet-down:before {
  content: "T";
}

.icon-charvlet-left:before {
  content: "U";
}

.icon-charvlet-right:before {
  content: "V";
}

.icon-charvlet-up:before {
  content: "W";
}

.icon-chat:before {
  content: "X";
}

.icon-chat-bubble:before {
  content: "Y";
}

.icon-chat-new:before {
  content: "Z";
}

.icon-check:before {
  content: "0";
}

.icon-check-circle-o:before {
  content: "1";
}

.icon-check-cricle:before {
  content: "2";
}

.icon-check-square:before {
  content: "3";
}

.icon-check-square-o:before {
  content: "4";
}

.icon-chevron-down:before {
  content: "5";
}

.icon-chevron-left:before {
  content: "6";
}

.icon-chevron-right:before {
  content: "7";
}

.icon-chevron-up:before {
  content: "8";
}

.icon-circle:before {
  content: "9";
}

.icon-circle-o:before {
  content: "!";
}

.icon-ckeditor:before {
  content: "\"";
}

.icon-close:before {
  content: "#";
}

.icon-close-circle:before {
  content: "$";
}

.icon-collapse:before {
  content: "%";
}

.icon-company:before {
  content: "&";
}

.icon-components:before {
  content: "'";
}

.icon-compose:before {
  content: "(";
}

.icon-contacts:before {
  content: ")";
}

.icon-copy:before {
  content: "*";
}

.icon-crm:before {
  content: "+";
}

.icon-crypto:before {
  content: ",";
}

.icon-culture-calendar:before {
  content: "-";
}

.icon-custom-view:before {
  content: ".";
}

.icon-dasbhoard:before {
  content: "/";
}

.icon-data-display:before {
  content: ":";
}

.icon-data-entry:before {
  content: ";";
}

.icon-datepicker:before {
  content: "<";
}

.icon-default-timeline:before {
  content: "=";
}

.icon-diamond:before {
  content: ">";
}

.icon-divider:before {
  content: "?";
}

.icon-donut:before {
  content: "@";
}

.icon-down:before {
  content: "[";
}

.icon-draft:before {
  content: "]";
}

.icon-drag-and-drop:before {
  content: "^";
}

.icon-dropdown:before {
  content: "_";
}

.icon-edit:before {
  content: "`";
}

.icon-editor:before {
  content: "{";
}

.icon-ellipse-h:before {
  content: "|";
}

.icon-ellipse-v:before {
  content: "}";
}

.icon-email:before {
  content: "~";
}

.icon-error:before {
  content: "\\";
}

.icon-error-404:before {
  content: "\E000";
}

.icon-error-500:before {
  content: "\E001";
}

.icon-etherium:before {
  content: "\E002";
}

.icon-exclamation:before {
  content: "\E003";
}

.icon-expand:before {
  content: "\E004";
}

.icon-extensions:before {
  content: "\E005";
}

.icon-extra-components:before {
  content: "\E006";
}

.icon-eye:before {
  content: "\E007";
}

.icon-facebook:before {
  content: "\E008";
}

.icon-family:before {
  content: "\E009";
}

.icon-feedback:before {
  content: "\E00A";
}

.icon-files:before {
  content: "\E00B";
}

.icon-filter:before {
  content: "\E00C";
}

.icon-filter-circle:before {
  content: "\E00D";
}

.icon-folder:before {
  content: "\E00E";
}

.icon-folder-o:before {
  content: "\E00F";
}

.icon-font:before {
  content: "\E010";
}

.icon-forgot-password:before {
  content: "\E011";
}

.icon-forward:before {
  content: "\E012";
}

.icon-forward-o:before {
  content: "\E013";
}

.icon-frequent:before {
  content: "\E014";
}

.icon-geo-location:before {
  content: "\E015";
}

.icon-graduation:before {
  content: "\E016";
}

.icon-growth:before {
  content: "\E017";
}

.icon-home:before {
  content: "\E018";
}

.icon-hotel-booking:before {
  content: "\E019";
}

.icon-icon:before {
  content: "\E01A";
}

.icon-image:before {
  content: "\E01B";
}

.icon-important:before {
  content: "\E01C";
}

.icon-important-o:before {
  content: "\E01D";
}

.icon-inbox:before {
  content: "\E01E";
}

.icon-inbuilt-apps:before {
  content: "\E01F";
}

.icon-input:before {
  content: "\E020";
}

.icon-inputnumber:before {
  content: "\E021";
}

.icon-invert-color:before {
  content: "\E022";
}

.icon-keyboard:before {
  content: "\E023";
}

.icon-like:before {
  content: "\E024";
}

.icon-like-o:before {
  content: "\E025";
}

.icon-link:before {
  content: "\E026";
}

.icon-lising-dbrd:before {
  content: "\E027";
}

.icon-list-select-o:before {
  content: "\E028";
}

.icon-listing-dbrd:before {
  content: "\E029";
}

.icon-litcoin:before {
  content: "\E02A";
}

.icon-localeprovider:before {
  content: "\E02B";
}

.icon-location:before {
  content: "\E02C";
}

.icon-lock-screen:before {
  content: "\E02D";
}

.icon-long-arrow:before {
  content: "\E02E";
}

.icon-long-arrow-down:before {
  content: "\E02F";
}

.icon-long-arrow-left:before {
  content: "\E030";
}

.icon-long-arrow-right:before {
  content: "\E031";
}

.icon-long-arrow-up:before {
  content: "\E032";
}

.icon-mail-open:before {
  content: "\E033";
}

.icon-map-clustering:before {
  content: "\E034";
}

.icon-map-directions:before {
  content: "\E035";
}

.icon-map-drawing:before {
  content: "\E036";
}

.icon-map-event-listener:before {
  content: "\E037";
}

.icon-map-google:before {
  content: "\E038";
}

.icon-map-km-layer:before {
  content: "\E039";
}

.icon-map-overlay:before {
  content: "\E03A";
}

.icon-map-popup-info:before {
  content: "\E03B";
}

.icon-map-selectable:before {
  content: "\E03C";
}

.icon-map-simple:before {
  content: "\E03D";
}

.icon-map-street-view:before {
  content: "\E03E";
}

.icon-map-styled:before {
  content: "\E03F";
}

.icon-map-traffic-layer:before {
  content: "\E040";
}

.icon-megaphone:before {
  content: "\E041";
}

.icon-mention:before {
  content: "\E042";
}

.icon-menu:before {
  content: "\E043";
}

.icon-menu-down:before {
  content: "\E044";
}

.icon-menu-fold:before {
  content: "\E045";
}

.icon-menu-left:before {
  content: "\E046";
}

.icon-menu-lines:before {
  content: "\E047";
}

.icon-menu-right:before {
  content: "\E048";
}

.icon-menu-select:before {
  content: "\E049";
}

.icon-menu-unfold:before {
  content: "\E04A";
}

.icon-menu-up:before {
  content: "\E04B";
}

.icon-message:before {
  content: "\E04C";
}

.icon-mic:before {
  content: "\E04D";
}

.icon-modal:before {
  content: "\E04E";
}

.icon-navigation:before {
  content: "\E04F";
}

.icon-noodles:before {
  content: "\E050";
}

.icon-notification:before {
  content: "\E051";
}

.icon-notification-new:before {
  content: "\E052";
}

.icon-orders:before {
  content: "\E053";
}

.icon-pagination:before {
  content: "\E054";
}

.icon-phone:before {
  content: "\E055";
}

.icon-picker:before {
  content: "\E056";
}

.icon-pizza:before {
  content: "\E057";
}

.icon-plain-list-divider:before {
  content: "\E058";
}

.icon-plain-list-view:before {
  content: "\E059";
}

.icon-popconfirm:before {
  content: "\E05A";
}

.icon-popover:before {
  content: "\E05B";
}

.icon-popup-calendar:before {
  content: "\E05C";
}

.icon-pricing-table:before {
  content: "\E05D";
}

.icon-product-grid:before {
  content: "\E05E";
}

.icon-product-list:before {
  content: "\E05F";
}

.icon-profile:before {
  content: "\E060";
}

.icon-profile2:before {
  content: "\E061";
}

.icon-progress:before {
  content: "\E062";
}

.icon-queries:before {
  content: "\E063";
}

.icon-question-circle:before {
  content: "\E064";
}

.icon-quote-backward:before {
  content: "\E065";
}

.icon-quote-forward:before {
  content: "\E066";
}

.icon-radiobutton:before {
  content: "\E067";
}

.icon-refer:before {
  content: "\E068";
}

.icon-rendaring-calendar:before {
  content: "\E069";
}

.icon-reply:before {
  content: "\E06A";
}

.icon-reply-o:before {
  content: "\E06B";
}

.icon-reset-password:before {
  content: "\E06C";
}

.icon-revenue-new:before {
  content: "\E06D";
}

.icon-ripple:before {
  content: "\E06E";
}

.icon-schedule:before {
  content: "\E06F";
}

.icon-search:before {
  content: "\E070";
}

.icon-search-new:before {
  content: "\E071";
}

.icon-select:before {
  content: "\E072";
}

.icon-selectable-map:before {
  content: "\E073";
}

.icon-sent:before {
  content: "\E074";
}

.icon-setting:before {
  content: "\E075";
}

.icon-shopping-cart:before {
  content: "\E076";
}

.icon-shuffle:before {
  content: "\E077";
}

.icon-signin:before {
  content: "\E078";
}

.icon-signup:before {
  content: "\E079";
}

.icon-slider:before {
  content: "\E07A";
}

.icon-social:before {
  content: "\E07B";
}

.icon-spam:before {
  content: "\E07C";
}

.icon-spin:before {
  content: "\E07D";
}

.icon-star:before {
  content: "\E07E";
}

.icon-star-half:before {
  content: "\E07F";
}

.icon-star-o:before {
  content: "\E080";
}

.icon-stats:before {
  content: "\E081";
}

.icon-steps:before {
  content: "\E082";
}

.icon-styled-map:before {
  content: "\E083";
}

.icon-sweet-alert:before {
  content: "\E084";
}

.icon-switch:before {
  content: "\E085";
}

.icon-tab:before {
  content: "\E086";
}

.icon-table:before {
  content: "\E087";
}

.icon-table-data:before {
  content: "\E088";
}

.icon-table-general:before {
  content: "\E089";
}

.icon-tag:before {
  content: "\E08A";
}

.icon-tag-new:before {
  content: "\E08B";
}

.icon-tag-o:before {
  content: "\E08C";
}

.icon-tasks:before {
  content: "\E08D";
}

.icon-team:before {
  content: "\E08E";
}

.icon-testimonial:before {
  content: "\E08F";
}

.icon-thumb-up:before {
  content: "\E090";
}

.icon-thumbs-down:before {
  content: "\E091";
}

.icon-ticket-new:before {
  content: "\E092";
}

.icon-tickets:before {
  content: "\E093";
}

.icon-timeline:before {
  content: "\E094";
}

.icon-timeline-left-align:before {
  content: "\E095";
}

.icon-timeline-new:before {
  content: "\E096";
}

.icon-timeline-with-icons:before {
  content: "\E097";
}

.icon-timepicker:before {
  content: "\E098";
}

.icon-tooltip:before {
  content: "\E099";
}

.icon-transfer:before {
  content: "\E09A";
}

.icon-translation:before {
  content: "\E09B";
}

.icon-trash:before {
  content: "\E09C";
}

.icon-tree:before {
  content: "\E09D";
}

.icon-treeselect:before {
  content: "\E09E";
}

.icon-uncheck-squire:before {
  content: "\E09F";
}

.icon-uncheck-squire-o:before {
  content: "\E0A0";
}

.icon-upload:before {
  content: "\E0A1";
}

.icon-user:before {
  content: "\E0A2";
}

.icon-user-o:before {
  content: "\E0A3";
}

.icon-view:before {
  content: "\E0A4";
}

.icon-view-o:before {
  content: "\E0A5";
}

.icon-visits:before {
  content: "\E0A6";
}

.icon-wall:before {
  content: "\E0A7";
}

.icon-widgets:before {
  content: "\E0A8";
}

.icon-wysiwyg:before {
  content: "\E0A9";
}

@font-face {
  font-family: 'ProximaNova';
  src: url(/static/media/ProximaNova-Regular.410504d4.otf) format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  src: url(/static/media/NoirPro-Light.defbb591.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NoirPro-Light.bbd56c69.woff) format('woff'), url(/static/media/NoirPro-Light.25188312.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(/static/media/NoirPro-Regular.752c13e1.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NoirPro-Regular.83b98671.woff) format('woff'), url(/static/media/NoirPro-Regular.fe706631.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(/static/media/NoirPro-Medium.6d74487d.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NoirPro-Medium.04b5d842.woff) format('woff'), url(/static/media/NoirPro-Medium.3d82e791.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(/static/media/NoirPro-SemiBold.ac97525f.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NoirPro-SemiBold.1bc66a4b.woff) format('woff'), url(/static/media/NoirPro-SemiBold.e928d2a2.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(/static/media/NoirPro-Bold.d9565816.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NoirPro-Bold.9dbcc95d.woff) format('woff'), url(/static/media/NoirPro-Bold.bb9d0cc6.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(/static/media/NoirPro-Heavy.7194b3b5.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NoirPro-Heavy.a302e546.woff) format('woff'), url(/static/media/NoirPro-Heavy.873c8fa8.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;
  /* width: 450px; */

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

